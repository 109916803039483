export default {
  init() {

    const forms = document.querySelectorAll('form.iko-form');

    forms.forEach(form => {
      const fields = form.querySelectorAll('[data-relational-field]:not([data-relational-field=""])');
      const formRules = get_rules(fields);
      const uniqueRelatedFieldIds = get_unique_related_field_ids(formRules);

      process_rules(form, formRules);

      uniqueRelatedFieldIds.forEach(id => {
        const nodes = form.querySelectorAll('[name*="message[' + id + ']"]');

        nodes.forEach(node => {
          if (!['select', 'input', 'textarea'].includes(node.tagName.toLowerCase())) {
            node = node.closest('input') ? node.closest('input')
              : node.closest('select') ? node.closest('select')
                : node.closest('textarea') ? node.closest('textarea')
                  : undefined;
          }

          if (node) {
            node.addEventListener('input', event => {
              process_rules(form, formRules);
              event.preventDefault();
            });
          }

        });
      });
    });

    function process_rules(form, formRules) {
      const hiddenFields = form.querySelectorAll('.column.is-hidden');

      if (hiddenFields.length) {
        hiddenFields.forEach(field => {
          show_element(field);
        })
      }

      formRules.forEach(rule => {
        const fieldNode = form.querySelector('[name*="message[' + rule.field + ']"]');
        const relationalFieldNodes = Array.from(form.querySelectorAll('[name*="message[' + rule.relationalField + ']"]'));

        toggle_fields_based_on_rules({ relFields: relationalFieldNodes, condition: rule.condition, operator: rule.operator, field: fieldNode });
      });
    }

    function get_unique_related_field_ids(formRules) {
      const relatedFieldIds = formRules.map(rule => rule.relationalField);
      const uniqueRelatedFieldIds = [...new Set(relatedFieldIds)];

      return uniqueRelatedFieldIds;
    }

    function toggle_fields_based_on_rules({ relFields: relFields, condition: condition, operator: operator, field: field }) {

      if (operator == 'equals') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            relField.value != condition ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              relField.value.toLowerCase() != condition.toLowerCase() ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            selected.value.toLowerCase() != condition.toLowerCase() ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }

      else if (operator == 'doesNotEqual') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            relField.value == condition ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              relField.value.toLowerCase() == condition.toLowerCase() ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            selected.value.toLowerCase() == condition.toLowerCase() ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else if (operator == 'contains') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            !relField.valu.includes(condition) ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              !relField.value.toLowerCase().includes(condition.toLowerCase()) ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            !selected.value.toLowerCase().includes(condition.toLowerCase()) ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else if (operator == 'doesNotContain') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            relField.value.includes(condition) ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              relField.value.toLowerCase().includes(condition.toLowerCase()) ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            selected.value.toLowerCase().includes(condition.toLowerCase()) ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else if (operator == 'greaterThan') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            parseInt(relField.value) < parseInt(condition) ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              parseInt(relField.value) < parseInt(condition) ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            parseInt(selected.value) < parseInt(condition) ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else if (operator == 'lessThan') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            parseInt(relField.value) > parseInt(condition) ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              parseInt(relField.value) > parseInt(condition) ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            parseInt(selected.value) > parseInt(condition) ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else if (operator == 'empty') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            relField.value.length ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              relField.value.toLowerCase().length ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            selected.value.toLowerCase().length ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else if (operator == 'notEmpty') {
        const multipleSelectionResults = [];

        relFields.forEach(relField => {

          const fieldType = relField.tagName == 'INPUT' ? relField.getAttribute('type') : relField.tagName.toLowerCase();

          if (fieldType == 'text' || fieldType == 'number' || fieldType == 'url' || fieldType == 'tel' || fieldType == 'date' || fieldType == 'textarea') {
            !relField.value.length ? hide_element(field.closest('div.column')) : '';
          }

          if (fieldType == 'radio' || fieldType == 'checkbox') {
            if (relField.checked) {
              !relField.value.toLowerCase().length ? multipleSelectionResults.push(false) : multipleSelectionResults.push(true);
            }
          }

          if (fieldType == 'select') {
            const options = Array.from(relField.options);
            const selected = options.find(option => option.selected);

            !selected.value.toLowerCase().length ? hide_element(field.closest('div.column')) : ''
          }
        });

        if (multipleSelectionResults.length && !multipleSelectionResults.find(result => true)) {
          hide_element(field.closest('div.column'));
        }
      }
      else {
        fieldNode.classList.add('hide');
      }
    }

    function get_rules(fields) {
      const fieldList = Array.from(fields);
      let rules = fieldList.map(field => {
        const relationalField = field.getAttribute('data-relational-field');
        const logicalOperator = field.getAttribute('data-relational-operator');
        const condition = field.getAttribute('data-relational-condition');
        const form = field.closest('form');

        return {
          form: form.id,
          field: field.id,
          relationalField: relationalField,
          operator: logicalOperator,
          condition: condition
        }
      });
      return rules;
    }

    function hide_element(element) {
      element.classList.add('is-hidden');
    }

    function show_element(element) {
      element.classList.remove('is-hidden');
    }
  }
}