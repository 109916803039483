export default {
  init() {

    const viewport = get_viewport_sizes();
    const root = document.documentElement;
    root.style.setProperty('--viewport-height', viewport.vh + "px");
    root.style.setProperty('--viewport-width', viewport.vw + "px");

    function get_viewport_sizes() {
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      return {
        vh: vh,
        vw: vw
      }
    }
  },
  get_viewport() {
    const viewportWidth = getComputedStyle(document.querySelector('html')).getPropertyValue('--viewport-width');

    return viewportWidth;
  }
}