import './scss/index.scss';

import 'lazysizes';
import Viewport from './js/viewport';
import Form from './js/form';
// import Blog from './js/blog';

document.addEventListener('DOMContentLoaded', function () {

  // Add Viewport Sizes to :root
  Viewport.init();

  // Enable Active Classes and Hamburger of Navigation


  // Enable the Search Functionality in the Navigation



  if (document.querySelector('form.iko-form')) {
    Form.init();
  }

  if (window.location.href.includes('datenschutz') || window.location.href.includes('privacy')) {
    const button = document.querySelector('.page .iko-btn');

    button.addEventListener('click', e => {
      document.cookie = 'cookieconsent_status= ;expires=Thu, 01 Jan 1970 00:00:00 GMT';

      window.location.reload();

      e.preventDefault();
    });
  }
});